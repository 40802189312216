"use strict";

import tmsConfig from "@/utils/tmsConfig";
import { unique } from "@/utils/helpers";

export const postProcessMissingEntities = (array) => {
  // unique values
  let missing = unique(array);
  // nicer output format
  missing = missing.map((el) => {
    return el.split("_").join(" ");
  });
  // message to the user
  let message = missing.join(", ");
  message = message.substring(0, message.length);
  message = "Missing entities: " + message;
  return message;
};

// looks for missing obligatory entities and validates
export const getMissingEntities = (array) => {
  let missing = [];
  array.forEach((item) => {
    Object.entries(item).forEach((entry) => {
      const [entity, value] = entry;
      if (entity === "commodity")
        missing = missing.concat(getMissingEntities(value));
      else if (!value && tmsConfig.tmsRequiredEntities.includes(entity))
        missing.push(entity);
    });
  });
  return missing;
};

export const validateMissingEntities = (trips) => {
  if (trips.length > 0) {
    const missing = getMissingEntities(trips);
    const message = postProcessMissingEntities(missing);
    return [missing.length === 0, message];
  } else {
    return [false, "Please, You must create at least one truck posting."];
  }
};
