<template>
  <div>
    <div fluid class="html-wrapper" v-html="html"></div>
  </div>
</template>

<script>
export default {
  name: "HTMLSection",
  props: ["html"]
};
</script>

<style scoped></style>
