<template>
  <b-card class="m-3">
    <template #header>
      <div
        class="additional-info-form-header px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <strong>Additional Info</strong>
        <b-button @click="$emit('setView', -1)">X</b-button>
      </div>
    </template>
    <b-card-body class="p-3">
      <b-row>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            size="sm"
            class="mb-2 pl-2"
            placeholder="Carrier state province code"
            v-model="carrierStateProvinceCode"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            size="sm"
            class="mb-2 pl-2"
            placeholder="Comments"
            v-model="comments"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            type="email"
            size="sm"
            class="mb-2 pl-2"
            placeholder="Contact Email"
            v-model="contactEmail"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            size="sm"
            class="mb-2 pl-2"
            placeholder="Contact Name"
            v-model="contactName"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            size="sm"
            class="mb-2 pl-2"
            placeholder="Contact Phone"
            v-model="contactPhone"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            size="sm"
            class="mb-2 pl-2"
            placeholder="Data source"
            v-model="dataSource"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            type="number"
            size="sm"
            class="mb-2 pl-2"
            placeholder="Day of week available"
            v-model.number="dayOfWeekAvailable"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            size="sm"
            class="mb-2 pl-2"
            placeholder="Datetime created"
            v-model="datetimeCreated"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            type="number"
            size="sm"
            class="mb-2 pl-2"
            placeholder="Delivery range (miles)"
            v-model.number="deliveryRangeInMiles"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            type="number"
            step="0.000001"
            size="sm"
            class="mb-2 pl-2"
            placeholder="Destination latitude"
            v-model.number="destinationLatitude"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            type="number"
            step="0.000001"
            size="sm"
            class="mb-2 pl-2"
            placeholder="Destination longitude"
            v-model.number="destinationLongitude"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            size="sm"
            class="mb-2 pl-2"
            placeholder="Equipment options"
            v-model="equipmentOptions"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            type="number"
            size="sm"
            class="mb-2 pl-2"
            placeholder="Minimum miles"
            v-model.number="minimumMiles"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            type="number"
            size="sm"
            class="mb-2 pl-2"
            placeholder="Number available"
            v-model.number="numberAvailable"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            type="number"
            step="0.000001"
            size="sm"
            class="mb-2 pl-2"
            placeholder="Origin latitude"
            v-model.number="originLatitude"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            type="number"
            step="0.000001"
            size="sm"
            class="mb-2 pl-2"
            placeholder="Origin longitude"
            v-model.number="originLongitude"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            type="number"
            size="sm"
            class="mb-2 pl-2"
            placeholder="Pickup range (miles)"
            v-model.number="pickupRangeInMiles"
          />
        </b-col>
        <b-col sm="12" md="4" lg="2">
          <b-form-input
            size="sm"
            class="mb-2 pl-2"
            placeholder="Truck posting ID"
            v-model="truckPostingId"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AdditionalInfoForm",
  props: {
    haulIndex: Number
  },
  computed: {
    ...mapGetters("emailDetails", ["trips"]),
    carrierStateProvinceCode: {
      get() {
        return this.trips[this.haulIndex].carrier_state_province_code;
      },
      set(value) {
        this.updateTrip({
          key: "carrier_state_province_code",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    comments: {
      get() {
        return this.trips[this.haulIndex].comments;
      },
      set(value) {
        this.updateTrip({ key: "comments", tripIndex: this.haulIndex, value });
      }
    },
    contactEmail: {
      get() {
        return this.trips[this.haulIndex].contact_email;
      },
      set(value) {
        this.updateTrip({
          key: "contact_email",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    contactName: {
      get() {
        return this.trips[this.haulIndex].contact_name;
      },
      set(value) {
        this.updateTrip({
          key: "contact_name",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    contactPhone: {
      get() {
        return this.trips[this.haulIndex].contact_phone;
      },
      set(value) {
        this.updateTrip({
          key: "contact_phone",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    dataSource: {
      get() {
        return this.trips[this.haulIndex].data_source;
      },
      set(value) {
        this.updateTrip({
          key: "data_source",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    dayOfWeekAvailable: {
      get() {
        return this.trips[this.haulIndex].day_of_week_available;
      },
      set(value) {
        this.updateTrip({
          key: "day_of_week_available",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    datetimeCreated: {
      get() {
        return this.trips[this.haulIndex].datetime_created;
      },
      set(value) {
        this.updateTrip({
          key: "datetime_created",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    deliveryRangeInMiles: {
      get() {
        return this.trips[this.haulIndex].delivery_range_in_miles;
      },
      set(value) {
        this.updateTrip({
          key: "delivery_range_in_miles",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    destinationLatitude: {
      get() {
        return this.trips[this.haulIndex].destination_latitude;
      },
      set(value) {
        this.updateTrip({
          key: "destination_latitude",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    destinationLongitude: {
      get() {
        return this.trips[this.haulIndex].destination_longitude;
      },
      set(value) {
        this.updateTrip({
          key: "destination_longitude",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    equipmentOptions: {
      get() {
        return this.trips[this.haulIndex].equipment_options;
      },
      set(value) {
        this.updateTrip({
          key: "equipment_options",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    minimumMiles: {
      get() {
        return this.trips[this.haulIndex].minimum_miles;
      },
      set(value) {
        this.updateTrip({
          key: "minimum_miles",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    numberAvailable: {
      get() {
        return this.trips[this.haulIndex].numberAvailable;
      },
      set(value) {
        this.updateTrip({
          key: "numberAvailable",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    originLatitude: {
      get() {
        return this.trips[this.haulIndex].origin_latitude;
      },
      set(value) {
        this.updateTrip({
          key: "origin_latitude",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    originLongitude: {
      get() {
        return this.trips[this.haulIndex].origin_longitude;
      },
      set(value) {
        this.updateTrip({
          key: "origin_longitude",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    pickupRangeInMiles: {
      get() {
        return this.trips[this.haulIndex].pickup_range_in_miles;
      },
      set(value) {
        this.updateTrip({
          key: "pickup_range_in_miles;",
          tripIndex: this.haulIndex,
          value
        });
      }
    },
    truckPostingId: {
      get() {
        return this.trips[this.haulIndex].truck_posting_id;
      },
      set(value) {
        this.updateTrip({
          key: "truck_posting_id",
          tripIndex: this.haulIndex,
          value
        });
      }
    }
  },
  methods: {
    ...mapMutations("emailDetails", ["updateTrip"])
  }
};
</script>

<style scoped>
.card {
  border-radius: 10px;
}

.card-header {
  padding: 0;
}

.additional-info-form-header {
  background-color: #29353c;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
