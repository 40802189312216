<template>
  <div class="email-details">
    <template v-if="isLoading">
      <LoadingSpinner text="Loading Email..." />
    </template>
    <template v-else>
      <div class="top-section clearfix mx-4 mt-2">
        <h4 class="email-details__subject-title">
          Subject: {{ selectedEmail.subject }}
        </h4>
      </div>
      <!-- <div class="email-details__split-container"> -->
      <b-button
        v-b-toggle.collapse-3
        class="p-2 mb-4 d-flex align-items-center"
        style="background-color: #17a2b8; border: none;"
        @click="visibleQuote = !visibleQuote"
        >Email Content<b-icon
          class="ml-2"
          :icon="visibleQuote ? 'chevron-double-up' : 'chevron-double-down'"
        ></b-icon
      ></b-button>
      <b-collapse class="border rounded mb-3 p-3" id="collapse-3">
        <div class="email-details__right-panel">
          <div class="email-details__centered"></div>
          <CustomAlert
            v-if="type === 'failed'"
            type="error"
            :content="selectedEmail.messageState"
          />
          <HTMLSection v-if="hasHTML" :html="selectedEmail.sentHtml" />
          <ImagesSection v-if="hasImages" />
        </div>
      </b-collapse>
      <div class="email-details__split-content">
        <div class="email-details__left-panel">
          <TripPanel :type="type" :editable="editable" :id="id" />
          <!-- <div class="mb-2">
          <strong v-if="type !== 'sent'">
            Total number of trucks to post:
            {{ selectedEmail.trips.length }}
          </strong>
          <strong v-else>
            Total number of trucks posted:
            {{ selectedEmail.trips.length }}
          </strong>
        </div> -->
          <div
            v-if="type === 'sent'"
            class="email-details__sent-html shadowed"
          ></div>
        </div>
      </div>
      <!-- <div class="email-details__splitter" @mousedown="startDragging"></div> -->
      <!-- <div
        class="email-details__split-content"
        :style="{ width: rightWidth + 'px' }"
      ></div> -->
      <!-- </div> -->
      <OptionButton
        :disabled="type === 'sent'"
        :loading="submitLoading"
        title="submit"
        @click.native="confirm"
      />
      <!-- old split -->
      <!-- <div class="sticky-section">
        <StickyEmail :content="stickyContent" @resizeSticky="resizeSticky" />
      </div> -->
    </template>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import emailListConfig from "../views/EmailList/EmailListConfig";
import * as shared from "@/utils/shared";
import tmsConfig from "@/utils/tmsConfig";
import LoadingSpinner from "@/components/LoadingSpinner";
import CustomAlert from "@/components/CustomAlert";
import TheHeader from "@/components/TheHeader";
import OptionButton from "@/components/OptionButton";
import TripPanel from "@/components/TripPanel";
import HTMLSection from "@/components/HTMLSection";
import ImagesSection from "@/components/ImagesSection";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { showAlert } from "@/utils/helpers";

export default {
  name: "EmailDetails",
  props: {
    id: String,
    type: {
      type: String,
      default: "details"
    },
    isGeneral: {
      type: Boolean,
      default: true
    }
  },
  components: {
    LoadingSpinner,
    // eslint-disable-next-line vue/no-unused-components
    CustomAlert,
    // eslint-disable-next-line vue/no-unused-components
    TheHeader,
    // eslint-disable-next-line vue/no-unused-components
    OptionButton,
    TripPanel,
    // eslint-disable-next-line vue/no-unused-components
    HTMLSection,
    // eslint-disable-next-line vue/no-unused-components
    ImagesSection
  },
  computed: {
    ...mapState("emailDetails", [
      "selectedEmail",
      "clientEmails",
      "emailTemplate",
      "emailContent"
    ]),
    ...mapGetters("emailDetails", ["trips"]),
    // old split
    /* sticky: function() {
      return this.$el.querySelector(".sticky-email");
    }, */
    infoTitle: function() {
      return this.selectedEmail.date;
    },
    incomplete: function() {
      return this.$el.querySelector(".incomplete > .toggle-switch");
    },
    editable: function() {
      return ["ner", "failed"].includes(this.type);
    },
    hasHTML() {
      return this.selectedEmail.sentHtml && this.selectedEmail.sentHtml !== "";
    },
    hasImages() {
      return (
        typeof this.selectedEmail.images !== "undefined" &&
        this.selectedEmail.images !== null &&
        this.selectedEmail.images.length > 0
      );
    }
    /* rightWidth() {
      return window.innerWidth - this.leftWidth - 8; // 8 es el ancho del splitter
    } */
  },
  data: function() {
    return {
      isLoading: true,
      stickyContent: String,
      // stickyContentState: Number,
      // stickyContentStatus: String,
      tmsName: tmsConfig.tmsName,
      submitLoading: false,
      isDragging: false,
      // leftWidth: 1300, // Ancho inicial del contenido izquierdo
      startX: 0,
      visibleQuote: true
    };
  },
  methods: {
    ...mapMutations("emailDetails", ["setClientEmails"]),
    ...mapActions("emailList", [
      "sendToNonLTL",
      "sendToLTL",
      "deleteEmailById"
    ]),
    ...mapActions("emailDetails", [
      "getEmailById",
      "getAttachments",
      "getTruckPostingsPerRequest",
      "addTrip",
      "deleteTrip",
      "sendHauls"
    ]),
    toggleSidebar: function() {
      const sidebar = this.$children.find((child) => {
        return child.$options.name === "TheSidebar";
      });
      sidebar.toggleSidebar();
    },
    //method to show emails original and processed email in the sticky panel
    // old split
    /* getEmailContent() {
      let content = "";
      if (this.stickyContentState === 0) {
        content = this.selectedEmail.html;
        this.stickyContentState = 1;
        this.stickyContentStatus = "Processed Email";
      } else if (this.stickyContentState === 1) {
        content =
          "<pre>" +
          document.querySelector(".content-panel").innerHTML +
          "</pre>";
        this.stickyContentState = 0;
        this.stickyContentStatus = "Original Email";
      }
      this.stickyContent = content;
    }, */
    // old split
    // adds the corresponding events when the sticky is going to be resized
    /* resizeSticky: function() {
      this.$el.addEventListener("mousemove", this.handleMouseMove);
      document.addEventListener("mouseup", () => {
        this.$el.removeEventListener("mousemove", this.handleMouseMove);
      });
    }, 
    renderSentHTML: function() {
      setTimeout(() => {
        const sentHTML = this.$el.querySelector(".sent-html");
        sentHTML.innerHTML = this.selectedEmail.sentHtml;
        sentHTML.style.display = "block";
      }, 300);
    }, */
    validate: function() {
      let [valid, message] = shared.validateMissingEntities(
        this.selectedEmail.trips
      );
      // If the quote is incomplete we must send the quote to the tms
      // if (!valid) valid = this.incomplete.checked;
      return [valid, message];
    },
    confirm: function() {
      //validation function before sending data
      const [valid, message] = this.validate();
      let notAcceptable = false;
      if (valid) {
        this.selectedEmail.trips.forEach((trip) => {
          if (
            trip["city_from"] === "Not Acceptable" ||
            trip["city_to"] === "Not Acceptable" ||
            trip["zip_from"] === "Not Acceptable" ||
            trip["zip_to"] === "Not Acceptable"
          ) {
            notAcceptable = true;
          }
        });
        if (notAcceptable)
          Swal.fire({
            title: "Are you sure?",
            text: "The quote cointains not acceptable values!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, send it!"
          }).then((result) => {
            if (result.value) this.sendQuote();
          });
        else this.sendQuote();
      } else {
        showAlert({
          alertType: "warning",
          title: "Oops... Cannot send quote",
          html: message
        });
      }
    },
    validateFields(hauls) {
      let validation = true;
      let validationMessage = "";
      hauls.forEach((haul) => {
        if (
          !haul.date_available ||
          haul.date_available === "" ||
          !haul.equipment_description ||
          haul.equipment_description === "" ||
          !haul.number_available ||
          haul.number_available === "" ||
          !haul.origin_city ||
          haul.origin_city === "" ||
          !haul.origin_state_province_code ||
          haul.origin_state_province_code === "" ||
          !haul.origin_country_code ||
          haul.origin_country_code === "" ||
          !haul.contact_email
        )
          validation = false;
        validationMessage =
          "date available, equipment description, number available, email and origin city-state-country are required";
      });
      return [validation, validationMessage];
    },
    // sends the quote to the tms API
    sendQuote: async function() {
      this.submitLoading = true;
      const payload = { quote_id: this.selectedEmail.id, hauls: this.trips };
      const quoteId = this.selectedEmail.id;
      const [validation, validationMessage] = this.validateFields(
        payload.hauls
      );
      if (!validation) {
        showAlert({
          alertType: "warning",
          title: "Oops... Cannot send quote",
          html: validationMessage
        });
        this.submitLoading = false;
      } else {
        try {
          const haul_ids = await this.sendHauls(quoteId);
          if (haul_ids.status === 200) {
            Swal.fire(
              "Great!",
              `Info was successfully sent: ${haul_ids?.data?.join(" - ")}`,
              "success"
            ).then(() => {
              this.$router.push({
                name: "emailList",
                params: { type: this.type }
              });
            });
          }
        } catch (err) {
          return err;
        } finally {
          this.submitLoading = false;
        }
      }
    },
    startDragging(e) {
      this.isDragging = true;
      this.startX = e.clientX;
      // document.addEventListener("mousemove", this.handleDragging);
      document.addEventListener("mouseup", this.stopDragging);
    },
    // handleDragging(e) {
    //   if (this.isDragging) {
    //     const dx = e.clientX - this.startX;
    //     this.leftWidth += dx;
    //     this.startX = e.clientX;
    //   }
    // },
    stopDragging() {
      this.isDragging = false;
      // document.removeEventListener("mousemove", this.handleDragging);
      document.removeEventListener("mouseup", this.stopDragging);
    }
  },
  created: async function() {
    this.$store.commit("setParams", emailListConfig[this.type]);
    this.$store.commit("setGeneral", { isGeneral: this.isGeneral });

    // this.stickyContentState = 0;
    // this.stickyContentStatus = "Processed Email";
    try {
      this.isLoading = true;
      const payload = { id: this.id };
      await this.getEmailById(payload);
      await this.getAttachments(payload);
      await this.getTruckPostingsPerRequest(payload);
      // if (!this.selectedEmail.isValid) throw new Error("invalid email");
      this.isLoading = false;
      // this.getEmailContent();
      // if (this.type === "sent") this.renderSentHTML();
    } catch (err) {
      console.error("[EMDET ERROR (360)]:", err.message);
      let statusCode;
      if (err.response) err.response.status;
      if (statusCode === 404) err.message = "Email not found";
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Could not get response from Charlie: ${err.message}`
      }).then(() => {
        let name;
        if (this.isGeneral) name = "generalEmailList";
        else name = "emailList";
        this.$router.push({
          name,
          params: { type: this.type }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.email-details {
  position: relative;
  margin: 0.5em 10px;
  padding-top: 50px;
  height: 100%;
  &__subject-title {
    margin: 2rem 0 1rem 0;
  }
  &__split-container {
    width: 100%;
    display: flex;
  }
  &__split-content {
    padding: 0.5rem;
    border: 1px solid #ccc;
    overflow: hidden;
  }
  &__splitter {
    width: 8px;
    cursor: col-resize;
    background: #ddd;
  }
  &__left-panel {
    height: 70vh;
    max-height: 70vh;
  }
  &__sent-html {
    display: none;
    background-color: #fff;
    padding: 3em 4em;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: center 0;
    -ms-transform-origin: center 0;
    transform-origin: center 0;
  }
  &__right-panel {
    width: 100%;
    height: 70vh;
    overflow-y: scroll;
  }
  &__right-panel::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  &__right-panel::-webkit-scrollbar-track {
    background-color: rgb(218, 218, 218);
  }
  &__right-panel::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 20px;
  }
  &__centered {
    text-align: center;
    word-wrap: break-word;
  }
}
// .sticky-email {
//   width: 20%;
// }
/* MEDIA QUERIES: RESPONSIVE DESIGN */
// @media (max-width: 767px) {
//   .content {
//     margin-right: 0;
//   }
//   .sticky-email {
//     display: none;
//   }
// }
// @media (min-width: 768px) and (max-width: 1023px) {
//   .content {
//     margin-right: calc(30% + 18px);
//   }
//   .sticky-email {
//     width: 30%;
//   }
// }
// @media (min-width: 1024px) and (max-width: 1279px) {
//   .content {
//     margin-right: calc(30% + 18px);
//   }
//   .sticky-email {
//     width: 30%;
//   }
// }
// @media (min-width: 1280px) and (max-width: 1799px) {
//   .content {
//     margin-right: calc(20% + 12px);
//   }
//   .sticky-email {
//     width: 20%;
//   }
// }
// @media (min-width: 1800px) {
//   .content {
//     margin-right: calc(20% + 9px);
//   }
//   .sticky-email {
//     width: 20%;
//   }
// }
</style>
