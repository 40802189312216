<template>
  <div class="details-table">
    <b-table
      fixed
      responsive
      show-empty
      empty-text="No elements to show"
      :no-border-collapse="true"
      :items="infoTrips"
      :fields="schema"
      class="small-font"
      :key="renderKey"
    >
      <!-- Table Header -->
      <template #thead-top>
        <b-tr class="text-light">
          <b-th colspan="2">
            <b-overlay
              :show="deletingPosting"
              rounded
              opacity="0.6"
              spinner-small
              class="d-inline-block mr-4"
            >
              <b-button
                size="sm"
                variant="info"
                :disabled="!editable"
                @click="deleteTrips"
              >
                Delete rows
              </b-button>
            </b-overlay>
          </b-th>
          <b-th colspan="2">
            <b-overlay
              :show="creatingPosting"
              rounded
              opacity="0.6"
              spinner-small
              class="d-inline-block mr-4"
            >
              <b-button
                size="sm"
                variant="info"
                :disabled="!editable"
                @click="newTruckPosting"
              >
                Add new row
              </b-button>
            </b-overlay>
          </b-th>
          <b-th colspan="4">
            <strong v-if="type !== 'sent'">
              Total number of trucks to post: {{ infoTrips.length }}
            </strong>
            <strong v-else>
              Total number of trucks posted: {{ infoTrips.length }}
            </strong>
          </b-th>
          <b-th colspan="6">
            <strong class="text-danger">
              <span v-if="errMessage !== null">{{ errMessage }}</span>
            </strong>
          </b-th>
        </b-tr>
        <b-tr>
          <b-th colspan="4">
            <b-form-group
              label-cols="6"
              label-cols-lg="4"
              label="Carrier name"
              label-for="input-carrier-name"
            >
              <b-form-input
                id="input-carrier-name"
                size="sm"
                :disabled="!editable"
                v-model="carrierName"
                @change="updatePostRequest('carrier_name', carrierName)"
                maxlength="60"
              ></b-form-input>
            </b-form-group>
          </b-th>
          <b-th colspan="4">
            <b-form-group
              label-cols="6"
              label-cols-lg="4"
              label="DOT Number"
              label-for="input-dot-number"
            >
              <b-form-input
                id="input-dot-number"
                size="sm"
                :disabled="!editable"
                v-model="dotNumber"
                @change="updatePostRequest('dot_number', dotNumber)"
                maxlength="10"
              ></b-form-input>
            </b-form-group>
          </b-th>
          <b-th colspan="4">
            <b-form-group
              label-cols="6"
              label-cols-lg="4"
              label="MC Number"
              label-for="input-mc-number"
            >
              <b-form-input
                id="input-mc-number"
                size="sm"
                :disabled="!editable"
                v-model="mcNumber"
                @change="updatePostRequest('mc_number', mcNumber)"
                maxlength="20"
              ></b-form-input>
            </b-form-group>
          </b-th>
          <b-th colspan="3"></b-th>
        </b-tr>
        <b-tr>
          <b-th
            class="text-nowrap"
            :key="index"
            v-for="(column, index) in schema"
          >
            <b-form-checkbox
              v-if="column.key === 'checkbox'"
              class="checkbox"
              v-model="allCheckboxesSelected"
            ></b-form-checkbox>
            <span v-else>{{ column.label }}</span>
            <template v-if="requiredEntities.includes(column.key)">
              <span :style="{ color: 'red' }">*</span></template
            >
          </b-th>
        </b-tr>
      </template>

      <!-- Cells -->
      <template #cell()="data">
        <b-form-textarea
          v-if="data.field.key != 'date_available'"
          autocomplete="off"
          rows="1"
          max-rows="4"
          v-model="data.item[data.field.key]"
          class="table-field"
          @focus="$event.target.select()"
          :disabled="!editable"
          @change="
            updateField(
              data.item[data.field.key],
              data.item.id,
              data.field.key,
              data.index
            )
          "
        >
        </b-form-textarea>
        <b-input
          v-else
          type="date"
          id="start"
          name="trip-start"
          v-model="dateAvailable[data.index]"
          :min="minDate"
          @focus="$event.target.select()"
          :disabled="!editable"
          @change="
            updateField(
              dateAvailable[data.index],
              data.item.id,
              data.field.key,
              data.index
            )
          "
        />
      </template>

      <template #cell(origin_country_code)="data">
        <b-input
          autocomplete="off"
          v-model="data.item.origin_country_code"
          class="table-field"
          :disabled="!editable"
          @change="
            updateField(
              data.item.origin_country_code,
              data.item.id,
              data.field.key
            ),
              changeOptionsTooltip(
                data.item.origin_country_code,
                data.index,
                'origin-country'
              )
          "
          v-tooltip.focus.bottom="'only these parameters are accepted: CA, US'"
        >
        </b-input>
        <!-- correct Dropdown -->
        <!-- <Dropdown
          v-model="data.item.origin_country_code"
          :options="cityAndStateOptions.countryOptions"
          optionLabel="name"
          :filter="true"
          placeholder="Select a Country"
          :showClear="true"
          @change="
            updateField(
              data.item.origin_country_code.code,
              data.item.id,
              data.field.key
            )
          "
        ></Dropdown> -->
      </template>

      <template #cell(origin_state_province_code)="data">
        <b-input
          autocomplete="off"
          v-model="data.item.origin_state_province_code"
          class="table-field"
          :disabled="!editable"
          @change="
            updateField(
              data.item.origin_state_province_code,
              data.item.id,
              data.field.key
            ),
              changeOptionsTooltip(
                data.item.origin_state_province_code,
                data.index
              )
          "
          v-tooltip.focus.bottom="
            `only states of ${data.item.origin_country_code} can be placed: ${
              originOptions[data.index]
            }`
          "
        >
        </b-input>
        <!-- correct Dropdown -->
        <!-- <Dropdown
          v-model="data.item.origin_state_province_code"
          :options="originOptions[data.index]"
          optionLabel="name"
          :filter="true"
          placeholder="Select a State"
          :showClear="true"
          @change="
            updateField(
              data.item.origin_state_province_code.code,
              data.item.id,
              data.field.key
            )
          "
        ></Dropdown> -->
      </template>

      <template #cell(destination_country_code)="data">
        <b-input
          autocomplete="off"
          v-model="data.item.destination_country_code"
          class="table-field"
          :disabled="!editable"
          @change="
            updateField(
              data.item.destination_country_code,
              data.item.id,
              data.field.key
            ),
              changeOptionsTooltip(
                data.item.destination_country_code,
                data.index,
                'destination-country'
              )
          "
          v-tooltip.focus.bottom="'only these parameters are accepted: CA, US'"
        >
        </b-input>
        <!-- correct Dropdown -->
        <!-- <Dropdown
          v-model="data.item.destination_country_code"
          :options="cityAndStateOptions.countryOptions"
          optionLabel="name"
          :filter="true"
          placeholder="Select a Country"
          :showClear="true"
          @change="
            updateField(
              data.item.destination_country_code.code,
              data.item.id,
              data.field.key
            )
          "
        ></Dropdown> -->
      </template>

      <template #cell(destination_state_province_code)="data">
        <b-input
          autocomplete="off"
          v-model="data.item.destination_state_province_code"
          class="table-field"
          :disabled="!editable"
          @change="
            updateField(
              data.item.destination_state_province_code,
              data.item.id,
              data.field.key
            ),
              changeOptionsTooltip(
                data.item.destination_state_province_code,
                data.index
              )
          "
          v-tooltip.focus.bottom="
            `only states of ${
              data.item.destination_country_code
            } can be placed: ${destinationOptions[data.index]}`
          "
        >
        </b-input>
        <!-- correct Dropdown -->
        <!-- <Dropdown
          v-model="data.item.destination_state_province_code"
          :options="destinationOptions[data.index]"
          optionLabel="name"
          :filter="true"
          placeholder="Select a State"
          :showClear="true"
          @change="
            updateField(
              data.item.destination_state_province_code.code,
              data.item.id,
              data.field.key
            )
          "
        ></Dropdown> -->
      </template>

      <!-- Cells checkboxes -->
      <template #cell(checkbox)="data">
        <b-form-checkbox
          class="checkbox-item"
          v-model="checkboxes[data.index]"
        ></b-form-checkbox>
      </template>

      <!-- Titles -->
      <template #head()="scope">
        <div class="text-nowrap invisible">
          {{ scope.label }}
          <template v-if="requiredEntities.includes(scope.column)">
            <span :style="{ color: 'red' }">*</span></template
          >
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
import { showAlert } from "@/utils/helpers";
// import Dropdown from "primevue/dropdown";
import { cityAndStateOptions } from "@/utils/cityAndStateOptions";

export default {
  name: "DetailsTable",
  props: {
    type: String,
    id: String,
    editable: Boolean
  },
  components: {
    // Dropdown
  },
  data() {
    return {
      errMessage: null,
      allCheckboxesSelected: false,
      checkboxes: null,
      schema: [
        { label: "", key: "checkbox" },
        { label: "Date Avail.", key: "date_available" },
        { label: "Eq. Desc.", key: "equipment_description" },
        { label: "Num. Avail", key: "number_available" },
        { label: "Or. Country", key: "origin_country_code" },
        { label: "Or. State", key: "origin_state_province_code" },
        { label: "Or. City", key: "origin_city" },
        { label: "Dest Country", key: "destination_country_code" },
        { label: "Dest. State", key: "destination_state_province_code" },
        { label: "Dest. City", key: "destination_city" },
        { label: "Contact Name", key: "contact_name" },
        { label: "Email", key: "contact_email" },
        { label: "Phone", key: "contact_phone" },
        { label: "Comments", key: "comments" }
      ],
      originState: null,
      destinationState: null,
      cityAndStateOptions: null,
      infoTrips: [],
      creatingPosting: false,
      deletingPosting: false,
      originOptions: [],
      destinationOptions: [],
      // momentary options
      usaOption: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "IA",
        "ID",
        "IL",
        "IN",
        "KS",
        "KY",
        "LA",
        "MA",
        "MD",
        "ME",
        "MI",
        "MN",
        "MO",
        "MS",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WI",
        "WV",
        "WY"
      ],
      canadaOptions: [
        "AB",
        "BC",
        "MB",
        "NB",
        "NL",
        "NS",
        "NT",
        "NU",
        "ON",
        "PE",
        "QC",
        "SK",
        "YT"
      ],
      renderKey: 0,
      carrierName: "",
      dotNumber: "",
      mcNumber: "",
      dateAvailable: [],
      minDate: moment().format("YYYY-MM-DD")
    };
  },
  computed: {
    ...mapState("emailDetails", ["selectedEmail"]),
    ...mapGetters("emailDetails", ["trips"]),
    requiredEntities() {
      return [
        "date_available",
        "equipment_description",
        "number_available",
        "origin_city",
        "origin_state_province_code",
        "origin_country_code",
        "contact_email"
      ];
    }
  },
  watch: {
    allCheckboxesSelected(newVal) {
      this.checkAllRows(newVal);
    }
    // Revisar map getter no esta funcionando en detailsTable.vue
    // trips: {
    //   handler(value) {
    //     console.log(value);
    //     this.infoTrips = this.trips;
    //   },
    //   deep: true
    // },
    // selectedEmail: {
    //   handler(value) {
    //     console.log(value);
    //   },
    //   deep: true
    // }
  },
  methods: {
    ...mapActions("user", ["logoutUser"]),
    ...mapActions("emailDetails", [
      "createTruckPosting",
      "deleteTruckPosting",
      "getTruckPostingsPerRequest",
      "updateTrip"
    ]),
    ...mapActions("emailList", ["updatePostingRequest"]),
    ...mapMutations("emailDetails", [
      // Delete dead code
      // "addTrip",
      "addRowToCommodity"
      // Delete dead code
      // "removeRowFromCommodity"
    ]),
    // Delete dead code
    // deleteTrip(index) {
    //   this.removeRowFromCommodity({ index });
    // },
    async deleteTrips() {
      try {
        this.deletingPosting = true;
        for (let i = this.checkboxes.length - 1; i >= 0; i--) {
          if (this.checkboxes[i]) {
            // this.deleteTrip(i);
            await this.deleteTruckPosting(i);
          }
        }
        const quoteId = this.selectedEmail?.id;
        await this.getTruckPostingsPerRequest({ id: quoteId });
        this.infoTrips = this.selectedEmail.trips;
        this.$emit("setView", -1);
        this.checkAllRows(false);
      } catch (err) {
        console.error("[DETTAB ERROR]:", err.message);
        this.errMessage = `${err.message}: could not delete rows`;
        setTimeout(() => (this.errMessage = null), 3000);
      } finally {
        this.deletingPosting = false;
      }
    },
    checkAllRows(state) {
      this.checkboxes.fill(state);
    },
    async updateField(fieldValue, truckPostingId, fieldName, index) {
      if (fieldName === "contact_email") {
        const validEmail = this.validateEmailText(fieldValue);
        if (validEmail) {
          try {
            this.isLoading = true;
            const payload = {};
            payload.truckPostingId = truckPostingId;
            payload.body = {};
            payload.body[fieldName] = fieldValue;
            await this.updateTrip(payload);
          } catch (err) {
            return err;
          }
        } else {
          showAlert({
            alertType: "warning",
            title: "Please check the field!",
            text: "Enter a valid email address."
          });
        }
      } else if (fieldName === "date_available") {
        let convertDate = fieldValue.split("-");
        convertDate = `${convertDate[1]}/${convertDate[2]}/${convertDate[0]}`;
        const validDate = this.validateDate(convertDate);
        if (validDate) {
          try {
            this.isLoading = true;
            const payload = {};
            payload.truckPostingId = truckPostingId;
            payload.body = {};
            payload.body[fieldName] = convertDate;
            await this.updateTrip(payload);
            this.infoTrips[index].date_available = fieldValue;
          } catch (err) {
            return err;
          }
        } else {
          showAlert({
            alertType: "warning",
            title: "Please check the field!",
            text: "Enter a valid date 'MM/DD/YYYY'."
          });
        }
      } else {
        if (fieldName === "number_available" && !fieldValue) {
          showAlert({
            alertType: "warning",
            title: "Please check the field!",
            text: "Enter available number."
          });
        } else {
          try {
            this.isLoading = true;
            const payload = {};
            payload.truckPostingId = truckPostingId;
            payload.body = {};
            payload.body[fieldName] = fieldValue ? fieldValue : null;
            await this.updateTrip(payload);
          } catch (err) {
            return err;
          }
        }
        this.isLoading = false;
      }
    },
    updateCountryAndState() {
      this.infoTrips.forEach((element, index) => {
        if (
          element.origin_country_code &&
          element.destination_country_code &&
          element.origin_state_province_code &&
          element.destination_state_province_code
        ) {
          this.cityAndStateOptions.countryOptions.find((country) => {
            if (country?.code === element.origin_country_code) {
              this.infoTrips[index].origin_country_code = country;
            } else if (country?.code === element.destination_country_code) {
              this.infoTrips[index].destination_country_code = country;
            }
          });
          if (this.infoTrips[index].origin_country_code?.code === "US") {
            this.originOptions[
              index
            ] = this.cityAndStateOptions.usaStateOptions;
            this.cityAndStateOptions.usaStateOptions.find((usaOption) => {
              if (usaOption?.code === element.origin_state_province_code) {
                this.infoTrips[index].origin_state_province_code = usaOption;
              }
            });
          }
          if (this.infoTrips[index].destination_country_code?.code === "US") {
            this.destinationOptions[
              index
            ] = this.cityAndStateOptions.usaStateOptions;
            this.cityAndStateOptions.usaStateOptions.find((usaOption) => {
              if (usaOption?.code === element.destination_state_province_code) {
                this.infoTrips[
                  index
                ].destination_state_province_code = usaOption;
              }
            });
          }
          if (this.infoTrips[index].origin_country_code?.code === "CA") {
            this.originOptions[
              index
            ] = this.cityAndStateOptions.canadaStateOptions;
            this.cityAndStateOptions.canadaStateOptions.find((canadaOption) => {
              if (canadaOption?.code === element.origin_state_province_code) {
                this.infoTrips[index].origin_state_province_code = canadaOption;
              }
            });
          }
          if (this.infoTrips[index].destination_country_code?.code === "CA") {
            this.destinationOptions[
              index
            ] = this.cityAndStateOptions.canadaStateOptions;
            this.cityAndStateOptions.canadaStateOptions.find((canadaOption) => {
              if (
                canadaOption?.code === element.destination_state_province_code
              ) {
                this.infoTrips[
                  index
                ].destination_state_province_code = canadaOption;
              }
            });
          }
        }
      });
    },
    async newTruckPosting() {
      this.creatingPosting = true;
      await this.createTruckPosting();
      this.creatingPosting = false;
    },
    changeOption(item, index) {
      this.originOptions[index] =
        this.infoTrips[index].origin_country_code?.code === "US"
          ? this.cityAndStateOptions.usaStateOptions
          : this.cityAndStateOptions.canadaStateOptions;
      this.destinationOptions = this.infoTrips[index].destination_country_code
        ?.code
        ? this.cityAndStateOptions.usaStateOptions
        : this.cityAndStateOptions.canadaStateOptions;
    },
    // momentary function
    changeOptionsTooltip(item, index, locationCountry) {
      if (locationCountry === "origin-country") {
        if (item === "CA") {
          this.originOptions[index] = this.canadaOptions;
        }
        if (item === "US") {
          this.originOptions[index] = this.usaOption;
        }
      }
      if (locationCountry === "destination-country") {
        if (item === "CA") {
          this.destinationOptions[index] = this.canadaOptions;
        }
        if (item === "US") {
          this.destinationOptions[index] = this.usaOption;
        }
      }
      this.renderKey += 1;
    },
    validateEmailText(emails) {
      if (emails !== null && emails !== undefined && emails !== "") {
        if (
          /^.*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\.[a-zA-Z0-9-]+)*$/.test(
            emails
          )
        ) {
          return true;
        }
      }
      return false;
    },
    validateDate(dateStr) {
      const formatoFecha = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
      if (!formatoFecha.test(dateStr)) {
        return false;
      }
      const partiesDate = dateStr.split("/");
      const month = parseInt(partiesDate[0], 10);
      const day = parseInt(partiesDate[1], 10);
      const year = parseInt(partiesDate[2], 10);
      let date = new Date(year, month - 1, day);
      return (
        date.getDate() === day &&
        date.getMonth() === month - 1 &&
        date.getFullYear() === year
      );
    },
    async updatePostRequest(fieldName, fieldValue) {
      const payload = {};
      payload.id = this.selectedEmail.id;
      payload.body = {};
      payload.body[fieldName] = fieldValue;
      await this.updatePostingRequest(payload);
    }
  },
  created() {
    this.infoTrips = this.trips;
    this.carrierName = this.selectedEmail.carrier_name;
    this.dotNumber = this.selectedEmail.dot_number;
    this.mcNumber = this.selectedEmail.mc_number;
    this.cityAndStateOptions = cityAndStateOptions;
    this.checkboxes = new Array(this.trips.length).fill(false);
    // momentary validation
    this.infoTrips.forEach((element, index) => {
      if (element.date_available) {
        let convertDate = element.date_available.split("/");
        convertDate = `${convertDate[2]}-${convertDate[0]}-${convertDate[1]}`;
        this.dateAvailable[index] = convertDate;
      }
      if (element.origin_country_code === "CA") {
        this.originOptions[index] = this.canadaOptions;
      }
      if (element.origin_country_code === "US") {
        this.originOptions[index] = this.usaOption;
      }
      if (element.destination_country_code === "CA") {
        this.destinationOptions[index] = this.canadaOptions;
      }
      if (element.destination_country_code === "US") {
        this.destinationOptions[index] = this.usaOption;
      }
    });
    // this.updateCountryAndState();
  }
};
</script>

<style lang="scss" scoped>
.details-table {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.28);
}

::v-deep textarea {
  width: auto;
  height: calc(1.5em + 0.75rem + 2px) !important;
  overflow: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 20px;
  }
}

::v-deep .p-dropdown {
  height: 40px;
}

body {
  padding: 1rem;
}

.b-table {
  max-height: 100% !important;
  overflow: scroll;
}
.table-responsive {
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgb(218, 218, 218);
  }
  &::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 20px;
  }
}

.small-font {
  font-size: 0.8em !important;
  height: 100%;
}
::v-deep .table thead th {
  border: none !important;
}
thead tr:nth-child(1) th {
  background-image: var(--details-table-header-gradient);
  position: sticky !important;
  top: 0;
  z-index: 3;
}

thead tr:nth-child(2) th {
  background-color: #999;
  position: sticky !important;
  top: 50px;
  z-index: 3;
}

thead tr:nth-child(3) th,
.thead-tr-class {
  background: #ced4da !important;
  position: sticky !important;
  top: 109px !important;
  z-index: 3 !important;
}
::v-deep .table thead tr:nth-child(4) {
  display: none;
}
.invisible {
  display: none;
}
// momentary style
tooltip {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  user-select: none;
  box-shadow: 0 0 10px #000;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  border-radius: 8px;
  top: 100%;
  left: 12.5%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
:has(> .tooltip) {
  position: relative;
}
:has(> .tooltip):hover tooltip {
  opacity: 1;
}
</style>
