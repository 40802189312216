<template>
  <div class="image-section w-100 mb-3">
    <div v-if="isLoading" class="loading-message alert alert-primary">
      <b-spinner small></b-spinner>
      Loading images...
    </div>
    <div v-else v-for="(image, index) in selectedEmail.images" :key="index">
      <b-container class="card rounded p-4">
        <!-- <iframe :src="image.url" frameborder="0"></iframe> -->
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="image.url"
          allowfullscreen
        ></b-embed>
        <!-- <b-img fluid :src="image"></b-img> -->
      </b-container>
    </div>
  </div>
</template>

<script>
// import Swal from "sweetalert2";
import { mapGetters /* , mapActions */ } from "vuex";

export default {
  name: "ImageSection",
  props: ["imageUrl"],
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters("emailDetails", ["selectedEmail"])
    // ...mapActions("emailDetails", ["getImage"]),
  },
  methods: {
    /* async getData() {
      try {
        this.isLoading = true;
        let promises = [];
        this.selectedEmail.images.forEach((image) => {
          if (typeof image !== "undefined" && image !== null && image !== "")
            promises.push(this.getImage(image));
        });
        await Promise.all(promises);
      } catch (err) {
        console.error(err.message);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.message
        });
      } finally {
        this.isLoading = false;
      }
    } */
  },
  async created() {
    // await this.getData();
  }
};
</script>

<style scoped>
.image-section {
  position: relative;
}

.loading-message {
  position: absolute;
  top: 0;
  left: 80px;
}
</style>
