export default {
  nonLtl: {
    status: 0,
    name: "Non LTL",
    sortingOrder: "desc",
    // Delete dead code
    // listSchema: ["id", "last_modified", "subject", "client_email"]
    listSchema: ["id", "updated_at", "subject", "client_email"]
  },
  created: {
    status: 1,
    name: "Create",
    sortingOrder: "desc",
    // Delete dead code
    // listSchema: ["id", "last_modified", "subject", "client_email"]
    listSchema: ["id", "received_at", "subject", "client_email", "assigned_to"]
  },
  // Delete dead code
  // ner: {
  //   status: 2,
  //   name: "LTL Queue",
  //   sortingOrder: "asc",
  //   listSchema: [
  //     "id",
  //     "last_modified",
  //     "subject",
  //     "client_email",
  //     "assigned_to"
  //   ]
  // },
  ner: {
    status: 2,
    name: "LTL Queue",
    sortingOrder: "asc",
    listSchema: ["id", "received_at", "subject", "client_email", "assigned_to"]
  },
  inProcess: {
    status: 3,
    name: "LTL In Process",
    sortingOrder: "asc",
    listSchema: [
      "id",
      "last_modified",
      "subject",
      "client_email",
      "assigned_to"
    ]
  },
  failed: {
    status: 4,
    name: "Failed Emails",
    sortingOrder: "desc",
    listSchema: [
      "id",
      "last_modified",
      "subject",
      "client_email",
      "assigned_to"
    ]
  },
  sent: {
    status: 3,
    name: "Sent Emails",
    sortingOrder: "desc",
    listSchema: [
      "id",
      "received_at",
      "subject",
      "client_email",
      // "quote_number",
      "assigned_to"
    ]
  },
  deleted: {
    status: 4,
    name: "Deleted Emails",
    sortingOrder: "desc",
    listSchema: ["id", "received_at", "subject", "client_email", "assigned_to"]
  }
};
