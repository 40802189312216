<template>
  <div class="option-button">
    <button class="button" :disabled="loading || disabled">
      <b-spinner
        v-if="loading"
        small
        variant="info"
        label="Text Centered"
        class="mr-1"
      ></b-spinner>
      {{ title }}
    </button>
  </div>
</template>

<script>
export default {
  name: "OptionButton",
  props: {
    title: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      buttonTitle: ""
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.option-button {
  margin: 0;
  display: inline-block;

  & > button.button {
    margin: 0;
    color: #ffffff;
    background-color: var(--button-bg-color);
    font-weight: 700;
    font-size: 11px;
    line-height: normal;
    text-transform: uppercase;
    border: none;
    border-radius: 3px;
    padding: 10px 16px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
      rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    transition: all 0.1s ease;
    outline: none;

    &:hover {
      background-color: var(--button-hover-color);
      transform: scale(1.05);
    }
  }
}
</style>
