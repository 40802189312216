<template>
  <div class="trip-panel" :class="{ collapsed: isCollapsed }">
    <template v-for="(haul, index) in trips">
      <template>
        <AdditionalInfoForm
          v-if="tripViewIndex === index"
          :key="index"
          :haulIndex="index"
          @setView="setView"
        ></AdditionalInfoForm>
      </template>
    </template>
    <DetailsTable
      :type="type"
      :editable="editable"
      :id="id"
      @setView="setView"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import AdditionalInfoForm from "@/components/AdditionalInfoForm.vue";
import DetailsTable from "@/components/DetailsTable.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "TripPanel",
  props: {
    id: String,
    type: String,
    editable: Boolean
  },
  components: {
    AdditionalInfoForm,
    DetailsTable
  },
  computed: {
    ...mapGetters("emailDetails", ["trips"])
  },
  data: function() {
    return {
      isCollapsed: false,
      tripViewIndex: -1
    };
  },
  methods: {
    ...mapMutations("emailDetails", ["addTrip", "deleteTrip"]),
    setView(value) {
      this.tripViewIndex = value;
    },
    addPanel: function() {
      if (this.editable) this.addTrip();
      else
        Swal.fire(
          "Oops...",
          `You cannot add trips in ${this.type} mode`,
          "error"
        );
    },
    dropPanel: function() {
      if (this.editable) {
        Swal.fire({
          icon: "warning",
          title: "Are you sure you want to delete this panel?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.value) {
            this.deleteTrip(this.id);
            Swal.fire("Deleted!", "Trip has been deleted.", "success");
          }
        });
      } else
        Swal.fire(
          "Oops...",
          `You cannot delete trips in ${this.type} mode`,
          "error"
        );
    }
    // togglePanel: function(button) {
    //   let panel = this.$el.querySelector(".table-wrapper");
    //   if (panel.style.maxHeight === "0px") {
    //     panel.style.maxHeight = panel.scrollHeight + "px";
    //     button.innerHTML = "&#x25B2;";
    //     this.isCollapsed = false;
    //   } else {
    //     panel.style.maxHeight = "0";
    //     button.innerHTML = "&#x25BC;";
    //     this.isCollapsed = true;
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.trip-panel {
  height: 100%;
}

.trip-info {
  margin-bottom: 15px;
}

.top-section {
  display: flex;
}

.buttons-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > button.side-button {
    background-color: var(--app-font-color);
    margin: 5px 0 5px 10px;
    display: inline-block;
    padding: 2px 4px;
    outline: none;
  }
}

.plus-button,
.minus-button {
  color: #ffffff;
  font-weight: bold;
  border: none;
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.05);
  }
}

button.side-button.toggle-button {
  padding: 0;
  font-size: 1.5em;
  background-color: transparent;
  color: #292929;
  border-style: none;
}

/* .table-wrapper {
  max-height: 2000px;
  overflow: hidden;
  transition: max-height 0.5s;
} */
</style>
