<template>
  <div class="header d-inline-block">
    <p>
      <strong>{{ title }}</strong>
    </p>
    <h4 class="pt-0" style="font-size: 1.5vw">
      <strong>SUBJECT:</strong> {{ subject }}
    </h4>
  </div>
</template>

<script>
export default {
  name: "TheHeader",
  props: {
    title: String,
    subject: String
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  padding: 10px 15px 0 15px;
  background-position: bottom;
  background-size: 100% auto;
  background-repeat: repeat-y;
  color: #111;
}

h4 {
  padding-top: 10px;
}
</style>
